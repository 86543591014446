/* Works.css */


.works {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(360px, 1fr));
    gap: 20px;
    /* grid-items間の隙間 */
    justify-content: center;
    /* grid-container全体を中央に寄せる */
    padding: 20px;
    /* 必要に応じてパディングを追加 */
}

.work-item {
    width: 360px;
    height: auto;
    cursor: pointer;
}

.work-item div img {
    width: 100%;
    height: 200px;
    object-fit: cover;
}

.work-item-image.active {
    animation: img-slide 2s ease;
}

@keyframes img-slide {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.work-item .title {
    color: #F5F5F5;
    font-size: 1.1em;
    font-weight: bold;
}

.cover-slide {
    position: relative;
    z-index: 0;
}