/* Flow.css */
.flow {
    width: 100%;
    height: auto;
    background-color: #333333;
    padding-top: 50px;
}

.steps {
    width: 90%;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
}

.step {
    width: 250px;
    margin: 10px;
    padding: 20px;
    text-align: center;
}

.step-arrow {
    position: relative;
    width: 100%;
    height: 40px;
    background: linear-gradient(to right, blue, cyan);
    color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 20px;
}

.step-arrow::after {
    content: "";
    position: absolute;
    top: 0;
    right: -20px;
    width: 0;
    height: 0;
    border-left: 20px solid cyan;
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
}

.step:hover .step-arrow {
    background: linear-gradient(to right, #e96443 0%, #904e95 100%);
}

.step:hover .step-arrow::after {
    border-left: 20px solid #904e95;
}

.step h3 {
    display: inline-block;
    margin: 20px auto 5px auto;
}

.step p {
    text-align: left;
}

@media (max-width: 768px) {
    .step {
        width: 300px;
    }

}