.contact {
    max-width: 600px;
    height: auto;
    margin: 100px auto 0 auto;
    box-sizing: border-box;
    padding-bottom: 50px;
}

.contact h2 {
    font-size: 2rem;
    margin-bottom: 20px;
    text-align: center;
}

.contact form {
    display: grid;
    gap: 10px;
}

.contact label {
    display: flex;
    flex-direction: column;
}

.contact input,
.contact textarea {
    color: black;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

.contact textarea {
    resize: vertical;
    min-height: 180px;
}

.contact button {
    width: 200px;
    margin: 20px auto;
    padding: 10px 20px;
    background-color: #007BFF;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.contact button:hover {
    background-color: #0056b3;
}

.confirm {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background-color: #333333;
}

.confirm p {
    padding: 20px;
}

.confirm button {
    width: 200px;
    margin: 0 25px;
}

@media (max-width: 768px) {
    .contact {
        width: 90%;

    }
}