@import 'reset.css';
@import url(http://fonts.googleapis.com/earlyaccess/notosansjp.css);
/* リセットCSSへのパスは適宜変更してください */
/* other CSS rules... */

body {
  margin: 0;
  font-family: 'Noto Sans JP', sans-serif, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #1E1E1E;
  color: var(--text-color);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --cover-color: #A55381;
  --text-color: #F5F5F5;
  --title-font-size: 3rem;
  --underline-color: #0081FF;
  --section-width: 190px;
  --section-height: 60px;
  --concept-width: 45%;
  --concept-margin-right: 50px;
}

.section-title {
  font-size: 2.7rem;
  position: relative;
  width: 160px;
  height: 60px;
  display: inline-block;
  margin-left: 20px;
}


.section-title::after {
  content: '';
  position: absolute;
  width: 0;
  height: 3px;
  bottom: 0;
  left: 0;
  background-color: var(--underline-color);
}

.section-title.active::after {
  animation: underline 2s ease forwards;
}

@keyframes underline {
  0% {
    width: 0;
  }

  50% {
    width: 100%;
  }

  100% {
    width: 50%;
  }
}


.cover-slide {
  position: relative;
  z-index: 0;
}

.cover-slide::after {
  content: '';
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.cover-slide.active::after {
  animation: kf-cover-slide 2s ease;
}

@keyframes kf-cover-slide {
  0% {
    background-color: var(--cover-color);
    left: 0;
    right: 100%;
  }

  50% {
    background-color: var(--cover-color);
    left: 0;
    right: 0;
  }

  100% {
    background-color: var(--cover-color);
    left: 100%;
    right: 0;
  }
}