.ContactButton {
    width: 250px;
    flex: 0 0 calc(33.33% - 20px);
    border: 1px solid #ccc;
    border-radius: 40px;
    padding: 15px;
    margin: 30px auto;
    transition: all 0.3s ease;
    cursor: pointer;
    background: linear-gradient(to right, #e96443 0%, #904e95 100%);
    position: relative;
    overflow: hidden;
    z-index: 1;
    text-align: center;
}

/* ::before疑似要素を追加 */
.ContactButton::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(to right, blue, cyan);
    transform: translateX(-100%);
    transition: 0.5s;
    z-index: -1;
}

.ContactButton:hover::before {
    transform: translateX(0);
}

.free-Contact-button {
    color: white;
    font-size: 1.2rem;
    text-decoration: none;
}