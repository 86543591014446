.skills {
    width: 100%;
    height: auto;
    margin: 200px auto 0 auto;
    position: relative;
    background-color: #50535D;
    padding: 50px 0 100px 0;
    /* overflow: hidden; */
}

.skills::before,
.skills::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100px;
    background: inherit;
}

.skills::before {
    top: -50px;
    transform: skewY(-3deg);
}

.skills::after {
    height: 100px;
    bottom: -50px;
    transform: skewY(-3deg);
}


.skills .skill-category .languages {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    gap: 100px;
}

.skills .skill-category .language {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.skill-category {
    margin-top: 50px;
}

.skill-category h3 {
    margin-left: 20px;
}

.language {
    margin-top: 30px;
}

.language img {
    width: 150px;
    height: 150px;
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.fade-in-up {
    animation-name: fadeInUp;
    animation-duration: 1s;
    animation-fill-mode: both;
}