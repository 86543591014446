/* Concept.css */

.section-title-l {
    font-size: 2.7rem;
    position: relative;
    width: 210px;
    height: 60px;
    display: inline-block;
    margin-left: 20px;
}


.section-title-l::after {
    content: '';
    position: absolute;
    width: 0;
    height: 3px;
    bottom: 0;
    left: 0;
    background-color: var(--underline-color);
}

.section-title-l.active::after {
    animation: underline 2s ease forwards;
}

@keyframes underline {
    0% {
        width: 0;
    }

    50% {
        width: 100%;
    }

    100% {
        width: 50%;
    }
}

.concept {
    width: 100%;
    height: auto;
    margin: 100px auto 0 auto;
    position: relative;
    background: #333333;
    padding: 50px 0 50px 0;
    /* overflow: hidden; */
}

.concept::before,
.concept::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100px;
    background: inherit;
}

.concept::before {
    top: -50px;
    transform: skewY(-3deg);
}

.concept::after {
    bottom: -30px;
    transform: skewY(-3deg);
}


.concept-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
    color: #F5F5F5;
}

.concept-left,
.concept-right {
    width: 45%;
    margin-right: 50px;
    overflow: hidden;
}

.concept-left img {
    width: 100%;
    height: auto;
}


.concept-image.active {
    animation: img-slide 2s ease;
}

@keyframes img-slide {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.concept-right h3 {
    font-size: 1.5rem;
    margin-bottom: 30px;
}

@media (max-width: 768px) {

    .concept-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        margin: 20px 0;
        color: #F5F5F5;
    }

    .concept-left,
    .concept-right {
        width: 100%;
        margin: 0 auto;
        overflow: hidden;
    }

    .concept-left img {
        width: 100%;
        height: auto;
    }

    .concept-right h3 {
        display: block;
        width: 90%;
        margin: 30px auto 20px auto;

    }

    .concept-right p {
        width: 90%;
        margin: 0 auto;
    }
}