.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    /* 背景を半透明の黒色にする */
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    z-index: 10;
}

.modal-content {
    background: #707070;
    padding: 20px;
    width: 80%;
    /* 適切なサイズに調整 */
    max-width: 800px;
    /* 適切なサイズに調整 */
    max-height: 95vh;
    overflow: auto;
}

.modal-inner {
    display: flex;
    flex-direction: row;
}

.modal-left {
    flex: 1;
    margin-right: 20px;
    margin-left: 50px;
}

.modal-right {
    flex: 1;
}

.language-badge {
    display: inline-flex;
    align-items: center;
    background-color: #f8f9fa;
    color: #333;
    padding: 5px 10px;
    margin: 5px;
}

.language-HTML {
    background-color: #e34c26;
}

.language-CSS {
    background-color: #264de4;
}

.language-JavaScript {
    background-color: #f0db4f;
}

.language-WordPress {
    background-color: #001479;
    color: #fff;
}

.language-PHP {
    background-color: #ff2cd1;
    color: #fff;
}

.language-React {
    background-color: #00D8FF;
    color: #fff;
}

.modal-close-button {
    position: fixed;
    background-color: transparent;
    /* 背景色を透明に */
    color: rgb(0, 0, 0);
    border: none;
    /* ボーダーを削除 */
    font-size: 50px;
    /* フォントサイズを大きく */
    cursor: pointer;
    /* カーソルを指マークに */
    outline: none;
    /* ボタンがフォーカスされた時のアウトラインを削除 */
    transition: transform 0.3s ease-in-out;
    transform-origin: center;
}

.modal-close-button:hover {
    transform: rotate(90deg);
    transition: transform 0.3s ease-in-out;
}

body.no-scroll {
    overflow: hidden;
}

@media (max-width: 768px) {
    .modal-content {
        padding: 20px;
        width: 90%;
        /* 適切なサイズに調整 */
        max-width: 800px;
        /* 適切なサイズに調整 */
        max-height: 95%;

    }

    .modal-inner {
        display: flex;
        flex-direction: column;
    }

    .modal-left {
        flex: 1;
        margin: 20px auto;
    }

    .modal-right {
        flex: 1;
        display: inline-block;
        margin: 10px auto 0 5%;
    }

    .modal-right h2 {
        font-size: 1.3rem;
    }

    .language {
        margin-top: 3px;
    }

    .modal-close-button {
        position: fixed;
        top: 10px;
    }
}