/* MainVisual.css */
.main-visual {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #1E1E1E;
    overflow: hidden;
    position: relative;
    z-index: -1;
}

.slideBackgraund1 {
    display: inline-block;
    overflow: hidden;
    position: absolute;
    top: 25vh;
    left: 10%;
    padding: 10px;
}

.slideBackgraund1::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, blue, cyan);
    transform: translateX(-100%);
    animation: slideBackground 2s forwards;
    z-index: -1;
}

.title1 {
    color: #F5F5F5;
    position: relative;
    white-space: nowrap;
    overflow: hidden;
    clip-path: inset(0 100% 0 0);
    animation: slideText 2s forwards;
}

.slideBackgraund2 {
    display: inline-block;
    overflow: hidden;
    position: absolute;
    top: 38vh;
    left: 10%;
    padding: 10px;
}

.slideBackgraund2::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, blue, cyan);
    transform: translateX(-100%);
    animation: slideBackground 2s forwards;
    animation-delay: 0.5s;
    z-index: -1;
}

.title2 {
    color: #F5F5F5;
    position: relative;
    white-space: nowrap;
    overflow: hidden;
    clip-path: inset(0 100% 0 0);
    animation: slideText 2s forwards;
    animation-delay: 0.5s;
}

.title3 {
    color: #F5F5F5;
    position: absolute;
    top: 55vh;
    right: 5%;
    white-space: nowrap;
    overflow: hidden;
    clip-path: inset(0 100% 0 0);
    animation: slideText 3.5s forwards;
    animation-delay: 0.7s;
}

@keyframes slideBackground {
    0% {
        transform: translateX(-100%);
    }

    100% {
        transform: translateX(0%);
    }
}

@keyframes slideText {
    0% {
        clip-path: inset(0 100% 0 0);
    }

    100% {
        clip-path: inset(0 0 0 0);
    }
}

@media (max-width: 768px) {
    .slideBackgraund1 {
        position: absolute;
        top: 25vh;
        left: 3%;
    }

    .title1 {
        font-size: 1.55rem;
    }

    .slideBackgraund2 {
        position: absolute;
        top: 38vh;
        left: 3%;
    }

    .title2 {
        font-size: 1.55rem;
    }

    .title3 {
        font-size: 1rem;
        position: absolute;
        top: 55vh;
        right: 3%;
    }
}