.price {
    width: 100%;
    margin-top: 100px;
    padding-top: 50px;
}

.pricing-cards {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.pricing-card {
    flex: 0 0 calc(33.33% - 20px);
    border: 1px solid #ccc;
    padding: 20px;
    margin: 10px;
    transition: all 0.3s ease;
    cursor: pointer;
    background: linear-gradient(to right, #1e3c72 0%, #2a5298 100%);
    color: white;
    position: relative;
    overflow: hidden;
    z-index: 1;
}

/* ::before疑似要素を追加 */
.pricing-card::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(to right, #e96443 0%, #904e95 100%);
    transform: translateX(-100%);
    transition: 0.5s;
    z-index: -1;
}

.pricing-card:hover::before {
    transform: translateX(0);
}

.pricing-card.active {
    transform: scale(1.05);
}

.pricing-card:not(.active):hover {
    transform: scale(1.02);
}

.pricing-card h3 {
    font-size: 1.2em;
    margin-bottom: 10px;
}

.pricing-card p {
    font-size: 1.8em;
}

.pricing-card p:nth-child(3) {
    font-size: 1em;
}

.plan-details {
    margin-top: 20px;
    border-top: 1px solid #ccc;
    padding-top: 20px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.plan-details h3 {
    font-size: 1.5em;
    margin-bottom: 10px;
    margin-right: 50px;
}

.plan-details table {
    border-collapse: collapse;
    text-align: center;
    width: 80%;

}

.plan-details td {
    border: 1px solid #ccc;
    padding: 10px;
}

.plan-details p {
    display: inline-block;
    width: 50vw;
    margin: 20px auto 0 auto;
}

.comparison h3 {
    margin-top: 50px;
    font-size: 1.5em;
    margin-left: 50px;
}

.comparison-table {
    width: 100%;
    margin: 0 auto;
    overflow-x: auto;
    /* tableタグのはみ出た要素を隠す */
    white-space: nowrap;
    /* テキストの折り返しなし */
}

.comparison-table table {
    border-collapse: collapse;
    width: 80%;
    margin: 20px auto;
}

.comparison-table th,
.comparison-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
}

.comparison-table th {
    background-color: #50535D;
    color: white;
}

@media (max-width: 768px) {
    .pricing-cards {
        display: block;
    }

    .pricing-card {
        display: block;
        max-width: 85%;
        margin: 15px auto;
        padding: 10px;
    }

    .plan-details p {
        width: 85%;
    }

    .comparison-table {
        width: 90%;
    }

}