footer {
    width: 100%;
    padding: 20px;
    background-color: #333333;
    box-sizing: border-box;
}

.profile h3 {
    margin-bottom: 10px;
}

.profile {
    width: auto;
}

.profileItem {
    max-width: 300px;
    border-top: 1px solid #DDDDDD;
    border-bottom: 1px solid #DDDDDD;
    padding: 20px 0;
}

.copyright {
    margin-top: 10px;
    margin-bottom: 5px;
    text-align: center;
}

@media (max-width: 768px) {

    .profileItem {
        max-width: 300px;
        margin: 0 auto;
    }

    .copyright {
        margin-top: 15px;
    }

}