/* Header.css */
header {
    height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background-color: #1E1E1E;
}

.logo {
    color: #F5F5F5;
    font-size: 32px;
}

nav {
    display: flex;
    margin: 0 20px 0 auto;
    padding: 0;
    text-align: center;
}

.navlink {
    font-size: 1.5rem;
    display: inline-block;
    padding-right: 15px;
    margin-right: 10px;
}

.navlink:hover {
    color: #0005FF;
    cursor: pointer;
}

.menu-icon {
    display: none;
}




@media (max-width: 768px) {
    .menu-icon {
        display: block;
        width: 40px;
        height: 30px;
        cursor: pointer;
        z-index: 10;
        position: fixed;
        top: 30px;
        right: 50px;
    }

    .menu-icon div {
        display: block;
        width: 100%;
        height: 4px;
        position: absolute;
        left: 0;
        background-color: #f8f8f8;
    }

    .menu:nth-child(1) {
        top: 0;
    }

    .menu:nth-child(2) {
        top: 50%;
        transform: translateY(-50%);
    }

    .menu:nth-child(3) {
        top: 100%;
        transform: translateY(-100%);
    }

    .menuOpen div:nth-child(1) {
        transform: rotate(-45deg) translateY(22px);
    }


    .menuOpen div:nth-child(2) {
        transform: rotate(0deg) scale(0.2, 0.2);
        opacity: 0;
    }

    .menuOpen div:nth-child(3) {
        transform: rotate(45deg) translateY(-22px);
    }


    header {
        position: relative;
    }

    nav {
        display: flex;
        flex-flow: column;
        width: 100vw;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 5;
        transform: translateX(-100%);
        transition: transform 0.5s ease-out;
    }

    nav div {
        display: inline-block;
        height: 30vw;
        background-color: #274D8F;
        padding: 25px;
        border: 1px solid blue;
        border-image: linear-gradient(to right, blue, cyan);
        border-image-slice: 1;
    }

    .open {
        transform: translateX(0);
        /* メニュー項目を画面内にスライドイン */
    }

    .open div:nth-child(2) {
        transition-delay: .05s;
    }

    .open div:nth-child(3) {
        transition-delay: .1s;
    }

    .open div:nth-child(4) {
        transition-delay: .15s;
    }

    .open div:nth-child(5) {
        transition-delay: .2s;
    }

    .open div:nth-child(6) {
        transition-delay: .25s;
    }

    .open div:nth-child(7) {
        transition-delay: .3s;
    }


}