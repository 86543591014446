/* service.css */
.service {
    width: 100%;
    height: auto;
    margin: 100px auto 0 auto;
    padding: 50px 0 50px 0;
}

.service-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
}

.service-left,
.service-right {
    width: 45%;
    overflow: hidden;
}

.service-right img {
    width: 100%;
    height: 500px;
}


.service-image.active {
    animation: img-slide 2s ease;
}

@keyframes img-slide {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.service-left h3 {
    font-size: 1.5rem;
    margin-bottom: 30px;
}

.service-left ul {
    margin-bottom: 30px;
    list-style: inside;
}

@media (max-width: 768px) {
    .service-container {
        display: flex;
        flex-wrap: wrap-reverse;
        justify-content: center;
        align-items: center;
        margin: 20px 0;
    }

    .service-left,
    .service-right {
        width: 100%;
        overflow: hidden;
    }

    .service-right img {
        width: 100%;
    }

    .service-left h3 {
        display: block;
        width: 90%;
        margin: 30px auto 20px auto;
    }

    .service-left ul {
        display: block;
        width: 90%;
        margin: 0 auto;
    }

    .service-left p {
        width: 90%;
        margin: 20px auto;
        font-size: 0.9rem;
    }
}